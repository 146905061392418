// src/pages/Login/LoginPage.jsx
import React, { useContext, useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate, useLocation } from 'react-router-dom';
import { StyledModal } from '../../components/Modal';
import { AuthContext } from '../../context/AuthContext';
import { useSpring, useTransition, animated } from 'react-spring';
import logo from '../../assets/login/logo.png';
import Swal from 'sweetalert2';
import { Helmet } from 'react-helmet';
import Navbar from '../../components/Navbar/Navbar';

import {
  BackgroundImage,
  Overlay,
  LoginForm,
  Logo,
  Input,
  Button,
  PlansTextLink,
  CharacterCircle,
  CharacterCircleContainer,
  GreetingContainer,
  GreetingTextStyled
} from './LoginStyles';
import { API_BASE_URL } from '../../utils/config';
import Footer from '../../components/Footer/Footer';
import 'react-toastify/dist/ReactToastify.css';

import RenewalModal from '../../components/Modal/RenewalModal';
import { StyledModal as CustomModal } from '../../components/Modal';

const greetings = ["¡Hola!", "Hello!", "Bonjour!", "Ciao!", "Hallo!"];

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [errors, setErrors] = useState({});

  const [showResetModal, setShowResetModal] = useState(false);
  const [isSubscriptionModalOpen, setIsSubscriptionModalOpen] = useState(false);
  const [showRenewalModal, setShowRenewalModal] = useState(false);
  const [showContactGestorModal, setShowContactGestorModal] = useState(false);

  const { setIsAuthenticated, setUserData, setSessionToken } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  const redirectTo = location.state?.redirectTo || '/dashboard';

  const [modalEmail, setModalEmail] = useState('');

  const footerRef = useRef(null);
  const [footerHeight, setFooterHeight] = useState(0);

  const formAnimation = useSpring({
    from: { opacity: 0, transform: 'scale(0.9)' },
    to: { opacity: 1, transform: 'scale(1)' },
    delay: 150
  });

  const [index, setIndex] = useState(0);
  const transitions = useTransition(index, {
    key: index,
    from: { opacity: 0, transform: 'translateY(-20px)' },
    enter: { opacity: 1, transform: 'translateY(0)' },
    leave: { opacity: 0, transform: 'translateY(20px)' },
    config: { tension: 50, friction: 20 }
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prev) => (prev + 1) % greetings.length);
    }, 4000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const emailParam = params.get('email');
    const passwordParam = params.get('password');

    if (emailParam) setEmail(emailParam);
    if (passwordParam) setPassword(passwordParam);

    if (emailParam && passwordParam) {
      handleSubmit();
    }
  }, []);

  useEffect(() => {
    if (footerRef.current) {
      const height = footerRef.current.offsetHeight;
      setFooterHeight(height);
    }
  }, [footerRef]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    const validationErrors = {};

    if (!email) {
      validationErrors.email = 'El correo electrónico es obligatorio';
    } else if (!/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(email)) {
      validationErrors.email = 'Ingrese un correo electrónico válido';
    }

    if (!password) {
      validationErrors.password = 'La contraseña es obligatoria';
    }

    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      try {
        const response = await axios.post(`${API_BASE_URL}/auth/login`, { email, password });

        if (response.data.success) {
          const userDataObj = {
            name: response.data.name,
            lastName: response.data.lastName,
            roles: response.data.roles,
            instituciones: response.data.instituciones,
            sedes: response.data.sedes,
            estado_contrasena: response.data.estado_contrasena,
            id_usuario: response.data.id_usuario,
            fotoPerfilUrl: response.data.fotoPerfilUrl
          };

          if (response.data.subscriptionActive === true) {
            const isPaciente = response.data.roles.some((role) => role.id === 1);
            if (isPaciente) {
              toast.warn(
                  'Lo sentimos, como usuario paciente no tienes acceso a este apartado. Por favor, visita la versión móvil de Vitalink.'
              );
              return;
            }
            userDataObj.sessionToken = response.data.sessionToken;

            localStorage.setItem('userData', JSON.stringify(userDataObj));
            localStorage.setItem('sessionToken', response.data.sessionToken);
            setUserData(userDataObj);
            setSessionToken(response.data.sessionToken);

            setIsAuthenticated(true);
            navigate(redirectTo);
          } else {

            localStorage.setItem('userData', JSON.stringify(userDataObj));
            localStorage.removeItem('sessionToken');
            setUserData(userDataObj);
            setSessionToken(null);

            const isGestor = response.data.roles.some((r) => r.id === 4);
            if (isGestor) {
              setShowRenewalModal(true);
            } else {
              setShowContactGestorModal(true);
            }
          }
        } else {
          toast.warn(response.data.error || 'Credenciales inválidas', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
            hideProgressBar: true
          });
        }
      } catch (error) {
        console.error('[handleSubmit] error =>', error);
        toast.error('Error en la autenticación, por favor intente nuevamente.', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
          hideProgressBar: true
        });
      }
    }
  };

  const handleForgotPasswordClick = () => {
    setShowResetModal(true);
  };

  const handleModalSubmit = async () => {
    try {
      const response = await axios.post(`${API_BASE_URL}/auth/olvidoContrasena`, { email: modalEmail });
      if (response.data.success) {
        toast.success('Se ha enviado un correo con instrucciones para restablecer la contraseña.');
        setShowResetModal(false);
      } else {
        toast.warn(response.data.error || 'No fue posible enviar el correo.');
      }
    } catch (error) {
      console.error('[handleModalSubmit] error =>', error);
      toast.error('Algo salió mal, intente de nuevo más tarde.');
    }
  };

  const navigateToPlans = () => {
    navigate('/planes');
  };

  const handleReactivate = () => {
    setIsSubscriptionModalOpen(false);
    navigate('/planes');
  };

  const handleRenewSuccess = () => {
    setShowRenewalModal(false);

    const storedUserData = localStorage.getItem('userData');
    if (storedUserData) {
      const userDataParsed = JSON.parse(storedUserData);
      userDataParsed.sessionToken = "dummyRenovatedToken";
      localStorage.setItem('userData', JSON.stringify(userDataParsed));
      localStorage.setItem('sessionToken', "dummyRenovatedToken");
      setUserData(userDataParsed);
      setSessionToken("dummyRenovatedToken");
    }

    setIsAuthenticated(true);
    navigate(redirectTo);
  };

  return (
      <>
        <Helmet>
          <title>Login - Vitalink</title>
          <meta
              name="description"
              content="Inicia sesión en Vitalink para gestionar tus citas médicas, historial de salud y ejercicios de rehabilitación."
          />
          <meta name="keywords" content="login, acceso, salud, gestión médica, citas online" />
        </Helmet>

        <Navbar />

        <BackgroundImage>
          <div className="bubble bubble1" />
          <div className="bubble bubble2" />
          <div className="bubble bubble3" />

          <GreetingContainer>
            {transitions((style, i) => (
                <animated.div
                    key={i}
                    style={{
                      ...style,
                      position: 'absolute',
                      left: '50%',
                      transform: 'translateX(-50%)'
                    }}
                >
                  <GreetingTextStyled>{greetings[i]}</GreetingTextStyled>
                </animated.div>
            ))}
          </GreetingContainer>

          <CharacterCircleContainer>
            <CharacterCircle />
          </CharacterCircleContainer>

          <Overlay>
            <animated.div style={formAnimation}>
              <LoginForm>
                <Logo src={logo} alt="logo" />

                <Input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    onKeyDown={handleKeyDown}
                />
                {errors.email && <div style={{ color: 'red' }}>{errors.email}</div>}

                <Input
                    type="password"
                    placeholder="Contraseña"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    onKeyDown={handleKeyDown}
                />
                {errors.password && <div style={{ color: 'red' }}>{errors.password}</div>}

                <Button
                    onClick={handleForgotPasswordClick}
                    style={{ marginTop: '30px' }}
                >
                  ¿Olvidaste tu contraseña?
                </Button>

                <Button
                    onClick={handleSubmit}
                    style={{ background: 'var(--rojo)' }}
                >
                  Ingresar
                </Button>

                <PlansTextLink onClick={navigateToPlans}>
                  ¿Primera vez con Vitalink? Conoce nuestros planes
                </PlansTextLink>
              </LoginForm>
            </animated.div>

            {/* Modal: recuperar contraseña */}
            <StyledModal
                isOpen={showResetModal}
                onRequestClose={() => {
                  setShowResetModal(false);
                }}
            >
              <h2>Recuperar contraseña</h2>
              <Input
                  type="email"
                  placeholder="Email"
                  value={modalEmail}
                  onChange={(e) => {
                    setModalEmail(e.target.value);
                  }}
              />
              <Button onClick={handleModalSubmit}>Recuperar Contraseña</Button>
              <Button
                  onClick={() => {
                    setShowResetModal(false);
                  }}
                  style={{ background: 'var(--rojo)' }}
              >
                Cancelar
              </Button>
            </StyledModal>

            {/* Modal: suscripción inactiva (no gestor) */}
            <StyledModal
                isOpen={isSubscriptionModalOpen}
                onRequestClose={() => {
                  setIsSubscriptionModalOpen(false);
                }}
            >
              <h2>Suscripción Inactiva</h2>
              <p>
                Necesitas reactivar tu suscripción para ingresar. Por favor, contacta al administrador
                o visita nuestra página de planes para renovar tu suscripción.
              </p>
              <div style={{ display: 'flex', gap: '10px', marginTop: '20px' }}>
                <Button
                    onClick={() => {
                      setIsSubscriptionModalOpen(false);
                    }}
                    style={{ background: 'var(--rojo)' }}
                >
                  Cerrar
                </Button>
                <Button onClick={handleReactivate}>Reactivar</Button>
              </div>
            </StyledModal>

            {/* Modal: renovación (gestor) */}
            <RenewalModal
                isOpen={showRenewalModal}
                onRequestClose={() => {
                  setShowRenewalModal(false);
                }}
                onRenewSuccess={handleRenewSuccess}
            />

            {/* Modal: contactar gestor (no es rol 4) */}
            <CustomModal
                isOpen={showContactGestorModal}
                onRequestClose={() => {
                  setShowContactGestorModal(false);
                }}
            >
              <h2>Renovación no disponible</h2>
              <p>Tu suscripción ha expirado. Por favor, contacta a tu usuario gestor para renovarla.</p>
              <Button
                  style={{ background: 'var(--rojo)' }}
                  onClick={() => {
                    setShowContactGestorModal(false);
                  }}
              >
                Cerrar
              </Button>
            </CustomModal>

            <ToastContainer />
          </Overlay>
        </BackgroundImage>
      </>
  );
};

export default LoginPage;
