import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import {
    Container,
    Content,
    Title,
    Input,
    ActionButtons,
    Button,
    FormColumn,
    FisioList,
    ListItem,
    FisioInfo,
    SelectButton,
    ButtonCancel,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    TextArea,
    PatientItem,
    PatientDetails,
    MotiveInput,
    PatientsList,
    PatientNumber,
    Label
} from './AltaPacienteStyle';

import { API_BASE_URL } from "../../utils/config";
import ActivityFeed from "../../components/Feed/FeedActividad";
import { StyledModal } from "../../components/Modal";

import { useTransition } from "react-spring";
import { AuthContext } from "../../context/AuthContext";
import Pagination from "../../components/Pagination/Pagination";
import { useSede } from "../../context/SedeContext";

const AltaPaciente = () => {
    // Campos de búsqueda
    const [nombre, setNombre] = useState('');
    const [apellido, setApellido] = useState('');
    const [email, setEmail] = useState('');

    // Listas principales
    const [pacientes, setPacientes] = useState([]);
    const [filteredPacientes, setFilteredPacientes] = useState([]);

    // Manejo de selección y eliminación
    const [selectedPatients, setSelectedPatients] = useState([]);
    const [selectedId, setSelectedId] = useState(null);

    // Modal para un solo paciente
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [motivo, setMotivo] = useState('');

    // Modal de confirmación para alta de múltiples pacientes
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

    // Paginación
    const [currentPage, setCurrentPage] = useState(1);
    const [patientsPerPage] = useState(10);

    // Checkbox para filtrar solo pacientes con +2 meses sin cita
    const [filtro2Meses, setFiltro2Meses] = useState(false);

    // Contextos
    const { userData } = useContext(AuthContext);
    const { idSedeActual } = useSede();

    // Indices para paginación
    const indexOfLastPatient = currentPage * patientsPerPage;
    const indexOfFirstPatient = indexOfLastPatient - patientsPerPage;
    const currentPatients = filteredPacientes.slice(indexOfFirstPatient, indexOfLastPatient);

    // -------------------------------------------
    // 1. Cargar pacientes cuando cambie la sede
    // -------------------------------------------
    useEffect(() => {
        axios.get(`${API_BASE_URL}/paciente/todosLosPacientes/${idSedeActual}`)
            .then(response => {
                if (response.data && Array.isArray(response.data.pacientes)) {
                    setPacientes(response.data.pacientes);
                    setFilteredPacientes(response.data.pacientes);
                } else {
                    setPacientes([]);
                    setFilteredPacientes([]);
                    toast.error('No se encontraron pacientes.', {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 5000,
                        hideProgressBar: true,
                    });
                }
            })
            .catch(error => {
                console.error('Error obteniendo pacientes:', error);
                setPacientes([]);
                setFilteredPacientes([]);
            });
    }, [idSedeActual]);

    // -------------------------------------------
    // 2. Filtrado por nombre, apellido, email y +2 meses
    // -------------------------------------------
    useEffect(() => {
        const filtered = pacientes.filter(p => {
            if (!p) return false;

            // Filtro por nombre
            if (nombre !== '') {
                const nombrePaciente = p.NOMBRE ? p.NOMBRE.toLowerCase() : '';
                if (!nombrePaciente.includes(nombre.toLowerCase())) {
                    return false;
                }
            }

            // Filtro por apellido
            if (apellido !== '') {
                const apellidoPaciente = p.APELLIDO ? p.APELLIDO.toLowerCase() : '';
                if (!apellidoPaciente.includes(apellido.toLowerCase())) {
                    return false;
                }
            }

            // Filtro por email
            if (email !== '') {
                const emailPaciente = p.EMAIL ? p.EMAIL.toLowerCase() : '';
                if (!emailPaciente.includes(email.toLowerCase())) {
                    return false;
                }
            }

            // Filtro por +2 meses sin cita
            if (filtro2Meses) {
                return p.masDe2MesesSinCita === 1;
            }

            return true;
        });

        setFilteredPacientes(filtered);
    }, [nombre, apellido, email, pacientes, filtro2Meses]);

    // -------------------------------------------
    // 3. Manejadores de selección
    // -------------------------------------------
    const togglePatientSelection = (patient) => {
        setSelectedPatients((prevSelected) => {
            const exists = prevSelected.find(p => p.ID_USUARIO === patient.ID_USUARIO);
            if (exists) {
                return prevSelected.filter(p => p.ID_USUARIO !== patient.ID_USUARIO);
            } else {
                return [...prevSelected, patient];
            }
        });
    };

    // -------------------------------------------
    // 4. Manejador para alta múltiple (botón "Dar de alta")
    // -------------------------------------------
    const handleConfirmOpen = () => {
        if (selectedPatients.length === 0) {
            toast.warn('No has seleccionado ningún paciente.', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000,
                hideProgressBar: true,
            });
            return;
        }
        setIsConfirmModalOpen(true);
    };

    // Cerrar modal de confirmación
    const handleConfirmClose = () => {
        setIsConfirmModalOpen(false);
    };

    // Enviar la eliminación (dar de alta) de varios pacientes
    const handleConfirmDischarge = () => {
        const patientIds = selectedPatients.map(patient => patient.ID_USUARIO);
        const motivos = selectedPatients.map(patient => patient.motivo || '');

        axios.delete(`${API_BASE_URL}/paciente/eliminarPacientes`, {
            data: {
                patientIds,
                motivos,
                userId: userData.id_usuario
            }
        })
            .then(response => {
                toast.success('Pacientes dados de alta exitosamente.', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 5000,
                    hideProgressBar: true,
                });
                // Quitar del listado
                setFilteredPacientes(prev => prev.filter(p => !patientIds.includes(p.ID_USUARIO)));
                setPacientes(prev => prev.filter(p => !patientIds.includes(p.ID_USUARIO)));
            })
            .catch(error => {
                console.error('Error al dar de alta pacientes:', error);
                toast.error('Error al dar de alta pacientes.', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 5000,
                    hideProgressBar: true,
                });
            });

        setIsConfirmModalOpen(false);
        setSelectedPatients([]);
    };

    // Capturar motivo de cada paciente
    const handleMotivoChange = (id, newMotivo) => {
        setSelectedPatients(selectedPatients.map(p =>
            p.ID_USUARIO === id ? { ...p, motivo: newMotivo } : p
        ));
    };

    // -------------------------------------------
    // 5. Dar de alta un solo paciente (OPCIONAL)
    // -------------------------------------------
    const handleModalOpen = (id) => {
        setSelectedId(id);
        setIsModalOpen(true);
    };
    const handleModalClose = () => {
        setIsModalOpen(false);
        setMotivo('');
    };

    const handleDelete = () => {
        if (!selectedId) {
            toast.warn('Por favor, selecciona un paciente para eliminar', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000,
                hideProgressBar: true,
            });
            return;
        }

        axios.delete(`${API_BASE_URL}/paciente/eliminarPaciente/${selectedId}`, {
            data: { userId: userData.id_usuario }
        })
            .then((response) => {
                if (response.data.success) {
                    toast.success('Paciente dado de alta exitosamente.', {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 5000,
                        hideProgressBar: true,
                    });
                    // Eliminarlo del listado
                    setFilteredPacientes(prev => prev.filter(p => p.ID_USUARIO !== selectedId));
                    setPacientes(prev => prev.filter(p => p.ID_USUARIO !== selectedId));
                } else {
                    toast.error('Error al dar de alta al paciente.', {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 5000,
                        hideProgressBar: true,
                    });
                }
                setIsModalOpen(false);
                setSelectedId(null);
                setMotivo('');
            })
            .catch((error) => {
                console.error('Error al dar de alta al paciente:', error);
                setIsModalOpen(false);
            });
    };

    // Paginación
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // React-spring (opcional) para animar el render
    const transitions = useTransition(filteredPacientes, {
        from: { opacity: 1, transform: 'translate3d(0,0px,0)' },
        enter: (item) => async (next) => {
            await next({ opacity: 1, transform: 'translate3d(0,0px,0)' });
        },
        leave: { opacity: 0, transform: 'translate3d(0,-40px,0)' },
        keys: (item) => item.ID_USUARIO
    });

    // -------------------------------------------
    // RENDER
    // -------------------------------------------
    return (
        <Container>
            <Content>
                <ToastContainer />

                <FormColumn className={"pacienteEliminar"}>
                    <Title>Dar de alta paciente</Title>

                    <Label>Buscar por nombre: </Label>
                    <Input
                        value={nombre}
                        onChange={e => setNombre(e.target.value)}
                    />

                    <Label>Buscar por apellido: </Label>
                    <Input
                        value={apellido}
                        onChange={e => setApellido(e.target.value)}
                    />

                    <Label>Buscar por correo electrónico: </Label>
                    <Input
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    />

                    {/* Checkbox para filtrar solo +2 meses sin cita */}
                    <div style={{ margin: '10px 0' }}>
                        <label>
                            <input
                                type="checkbox"
                                checked={filtro2Meses}
                                onChange={() => setFiltro2Meses(!filtro2Meses)}
                            />
                            &nbsp;Mostrar solo pacientes con +2 meses sin cita
                        </label>
                    </div>

                    {/* Listado de pacientes paginados */}
                    {currentPatients.length > 0 ? (
                        <FisioList>
                            {currentPatients.map(item => (
                                <ListItem key={item.ID_USUARIO}>
                                    <FisioInfo>
                                        {item.NOMBRE} {item.APELLIDO} ({item.EMAIL})
                                        {item.masDe2MesesSinCita === 1 && (
                                            <span style={{
                                                marginLeft: '10px',
                                                backgroundColor: 'var(--rojo-oscuro)',
                                                color: '#fff',
                                                padding: '3px 6px',
                                                borderRadius: '5px',
                                                fontSize: '0.8rem'
                                            }}>
                                                +2 meses sin cita
                                            </span>
                                        )}
                                    </FisioInfo>
                                    <SelectButton onClick={() => togglePatientSelection(item)}>
                                        {selectedPatients.some(p => p.ID_USUARIO === item.ID_USUARIO)
                                            ? 'Deseleccionar'
                                            : 'Seleccionar'
                                        }
                                    </SelectButton>
                                </ListItem>
                            ))}
                        </FisioList>
                    ) : (
                        <div>No se encontraron pacientes.</div>
                    )}

                    <Pagination
                        patientsPerPage={patientsPerPage}
                        totalPatients={filteredPacientes.length}
                        paginate={paginate}
                        currentPage={currentPage}
                    />

                    <ActionButtons>
                        <Button onClick={handleConfirmOpen}>Dar de alta</Button>
                    </ActionButtons>
                </FormColumn>

                {/* Actividad o Feed (si lo usas) */}
                <ActivityFeed
                    idRol={'4, 3, 2'}
                    idAccion={3}
                    idInstitucion={userData.id_institucion}
                    idEntidadAfectada={1}
                    className={"FeedActividades"}
                />
            </Content>

            {/* Modal de confirmación (alta de varios pacientes a la vez) */}
            <StyledModal isOpen={isConfirmModalOpen} onRequestClose={handleConfirmClose}>
                <ModalContent>
                    <ModalHeader>
                        <h2>Confirmar Alta de Pacientes</h2>
                    </ModalHeader>
                    <ModalBody>
                        <PatientsList>
                            {selectedPatients.map((patient, index) => (
                                <PatientItem key={patient.ID_USUARIO}>
                                    <PatientNumber>{index + 1}.</PatientNumber>
                                    <PatientDetails>
                                        {patient.NOMBRE} {patient.APELLIDO}
                                    </PatientDetails>
                                    <MotiveInput
                                        type="text"
                                        value={patient.motivo || ''}
                                        onChange={(e) => handleMotivoChange(patient.ID_USUARIO, e.target.value)}
                                        placeholder="Indica el motivo del alta"
                                    />
                                </PatientItem>
                            ))}
                        </PatientsList>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={handleConfirmDischarge}>Confirmar Alta</Button>
                        <ButtonCancel onClick={handleConfirmClose} cancelBtn>
                            Cancelar
                        </ButtonCancel>
                    </ModalFooter>
                </ModalContent>
            </StyledModal>

            {/* Modal para dar de alta un solo paciente (opcional) */}
            <StyledModal isOpen={isModalOpen} onRequestClose={handleModalClose}>
                <ModalContent>
                    <ModalHeader>
                        <h2>Dar de Alta Paciente</h2>
                    </ModalHeader>
                    <ModalBody>
                        <TextArea
                            placeholder="Motivo de dar de alta"
                            value={motivo}
                            onChange={(e) => setMotivo(e.target.value)}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={handleDelete}>Eliminar</Button>
                        <ButtonCancel onClick={handleModalClose} cancelBtn>
                            Cancelar
                        </ButtonCancel>
                    </ModalFooter>
                </ModalContent>
            </StyledModal>
        </Container>
    );
};

export default AltaPaciente;
