// Footer.js
import React, { useState } from 'react';
import { FaTwitter, FaInstagram, FaPinterest, FaFacebookF, FaMailBulk } from 'react-icons/fa';
import { FooterContainer, FooterText, BrandName, SocialMediaIcons, FooterRights } from './FooterStyle';
import TermsAndConditionsViewModal from '../TermsAndConditionsViewModal/TermsAndConditionsViewModal';

const Footer = React.forwardRef((props, ref) => {
    const [showTermsModal, setShowTermsModal] = useState(false);

    return (
        <>
            <FooterContainer ref={ref}>
                <SocialMediaIcons>
                    <a href="mailto:soporte@vitalink.es" target="_blank" rel="noopener noreferrer">
                        <FaMailBulk />
                    </a>
                </SocialMediaIcons>
                <FooterRights>
                    <FooterText>
                        Todos los derechos reservados a{' '}
                        <BrandName href="https://haricode.com" target="_blank" rel="noopener noreferrer">
                            Haricode
                        </BrandName>
                        {' '}|{' '}
                        <span
                            onClick={() => setShowTermsModal(true)}
                            style={{ cursor: 'pointer', textDecoration: 'underline' }}
                        >
              Términos y Condiciones
            </span>
                    </FooterText>
                </FooterRights>
            </FooterContainer>
            <TermsAndConditionsViewModal
                isOpen={showTermsModal}
                onRequestClose={() => setShowTermsModal(false)}
            />
        </>
    );
});

export default Footer;
