// CornerNotificationsStyle.js
import styled from 'styled-components';

/* Eliminamos el borde, background y sombras extra
   para que no parezca una “card” dentro de otra */
export const NotificationContainer = styled.div`
    width: 100%;
    margin-top: 15px;
`;

export const NotificationTitle = styled.h3`
    margin: 0 0 10px 0;
    font-size: 1rem;
    font-weight: 700;
`;

export const NotificationList = styled.div`
    max-height: 220px;
    overflow-y: auto;
    padding-right: 5px;

    &::-webkit-scrollbar {
        width: 6px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #999;
        border-radius: 10px;
    }
`;

export const NotificationItem = styled.div`
    padding: 10px;
    margin-bottom: 6px;
    border-radius: 4px;
    background-color: #fafafa;
    transition: background-color 0.2s ease;

    &:hover {
        background-color: #f2f2f2;
    }
`;

export const NotificationItemTitle = styled.div`
    font-weight: bold;
    margin-bottom: 2px;
`;

export const NotificationItemDesc = styled.div`
    font-size: 0.9rem;
    color: #666;
`;
