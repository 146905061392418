import React, { useContext, useEffect, useRef, useState } from 'react';
import { FaArrowLeft, FaBuilding, FaTimes } from 'react-icons/fa';
import {
    PopupWindow,
    MenuItem,
    FormContainer,
    Input,
    TextArea,
    Button,
    HeaderContainer,
    BackButton,
    CloseButton,
    ModalBackdrop,
    IconContainer,
    StyledDropdownContainer,
    FloatingButton,
    StyledOption,
    NotificationDot,
    MessagePopup
} from './HeartIconAnimationStyle';

import Tutorial from "../Tutorial/Tutorial";
import { API_BASE_URL } from "../../utils/config";
import { toast, ToastContainer } from "react-toastify";
import Vita from "./img/vita.png";
import { AuthContext } from "../../context/AuthContext";
import { useSede } from '../../context/SedeContext';
import CornerNotifications from '../CornerNotifications/CornerNotifications';

const HeartIconAnimation = ({ notifications = [] }) => {
    const { userData } = useContext(AuthContext);
    const { idSedeActual, changeSede } = useSede();

    const [showPopup, setShowPopup] = useState(false);
    const [showContactForm, setShowContactForm] = useState(false);
    const [showNotifications, setShowNotifications] = useState(false);
    const animationContainer = useRef(null);

    const [selectedSede, setSelectedSede] = useState(idSedeActual);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [isIconVisible, setIsIconVisible] = useState(false);
    const [formData, setFormData] = useState({
        nombreCompleto: '',
        email: '',
        mensaje: '',
    });
    const [isTutorialActive, setIsTutorialActive] = useState(false);

    // Estado para mostrar el popup inicial (solo se mostrará una vez)
    const [showInitialNotification, setShowInitialNotification] = useState(false);
    const initialNotificationShown = useRef(false);
    const iconSize = 65; // Ajusta a tu gusto

    // Al montar, ocultamos el ícono hacia la derecha
    useEffect(() => {
        if (animationContainer.current) {
            animationContainer.current.style.right = '-25px';
        }
    }, []);

    // Si hay notificaciones y no se ha mostrado el popup inicial, lo mostramos una sola vez
    useEffect(() => {
        if (notifications.length > 0 && !initialNotificationShown.current) {
            setShowInitialNotification(true);
            initialNotificationShown.current = true;
            // Ocultar después de 5 segundos
            const timer = setTimeout(() => {
                setShowInitialNotification(false);
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [notifications]);

    const togglePopup = () => {
        setShowPopup(!showPopup);
        if (animationContainer.current) {
            if (!showPopup) {
                animationContainer.current.style.right = '20px';
                setIsIconVisible(true);
            } else {
                animationContainer.current.style.right = '-60px';
                setIsIconVisible(false);
            }
        }
        // Reseteamos secciones internas
        setShowContactForm(false);
        setShowNotifications(false);
    };

    const closePopup = () => {
        setShowPopup(false);
        if (animationContainer.current) {
            animationContainer.current.style.right = '-40px';
            setIsIconVisible(false);
        }
    };

    const handleContactClick = () => {
        setShowContactForm(true);
        setShowNotifications(false);
    };

    const handleNotificationsClick = () => {
        setShowNotifications(true);
        setShowContactForm(false);
    };

    const handleBackClick = () => {
        setShowContactForm(false);
        setShowNotifications(false);
    };

    const handleInputEmailChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmitEmail = (e) => {
        e.preventDefault();
        fetch(`${API_BASE_URL}/asistente/emailSoporte`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formData),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    toast.success("Correo enviado exitosamente", {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 5000,
                        hideProgressBar: true,
                    });
                } else {
                    toast.error("Ocurrió un error, contacte con su administrador", {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 5000,
                        hideProgressBar: true,
                    });
                }
            })
            .catch((error) => {
                alert('Hubo un error al enviar el mensaje.');
                console.error('Error:', error);
            });
    };

    const startTutorial = () => {
        setIsTutorialActive(true);
        setShowPopup(false);
    };

    const closeTutorial = () => {
        setIsTutorialActive(false);
    };

    const handleSedeChange = (e) => {
        const newSedeId = e.target.value;
        setSelectedSede(newSedeId);
        changeSede(newSedeId);
    };

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    // Permite cerrar manualmente el popup inicial
    const handleCloseInitialNotification = () => {
        setShowInitialNotification(false);
    };

    // Obtenemos el último recordatorio (último elemento del array)
    const lastNotification = notifications[notifications.length - 1] || null;

    return (
        <>
            {showPopup && <ModalBackdrop onClick={closePopup} />}

            {/* Popup inicial de notificación (se muestra solo una vez) */}
            {showInitialNotification && lastNotification && (
                <MessagePopup>
                    <button className="close-btn" onClick={handleCloseInitialNotification}>
                        <FaTimes />
                    </button>
                    <strong>{lastNotification.titulo}</strong>
                    <p>{lastNotification.descripcion}</p>
                </MessagePopup>
            )}

            {/* Ícono flotante (Vita) */}
            <IconContainer
                ref={animationContainer}
                onClick={togglePopup}
                size={iconSize}
                visible={isIconVisible}
            >
                <img src={Vita} alt="Vita" width={iconSize} height={iconSize} />
            </IconContainer>

            {/* Dropdown de sedes (si hay más de una) */}
            {userData?.sedes?.length > 1 && (
                <FloatingButton onClick={toggleDropdown}>
                    <FaBuilding />
                    <StyledDropdownContainer>
                        <select
                            value={selectedSede || ''}
                            onChange={handleSedeChange}
                            style={{
                                padding: '0.5rem',
                                borderRadius: '5px',
                                border: 'none',
                                background: 'var(--celeste)',
                                color: 'var(--blanco)',
                                fontWeight: 700,
                                cursor: 'pointer',
                            }}
                        >
                            {userData.sedes.map((sede) => (
                                <StyledOption key={sede.ID_SEDE} value={sede.ID_SEDE}>
                                    {sede.NOMBRE}
                                </StyledOption>
                            ))}
                        </select>
                    </StyledDropdownContainer>
                </FloatingButton>
            )}

            {showPopup && (
                <PopupWindow>
                    <HeaderContainer>
                        {(showContactForm || showNotifications) && (
                            <BackButton onClick={handleBackClick}>
                                <FaArrowLeft />
                                <span>Volver</span>
                            </BackButton>
                        )}
                        <CloseButton onClick={closePopup}>
                            <FaTimes />
                        </CloseButton>
                    </HeaderContainer>

                    {!showContactForm && !showNotifications && (
                        <>
                            <MenuItem onClick={handleContactClick}>
                                Contactar a soporte
                            </MenuItem>
                            <MenuItem onClick={handleNotificationsClick}>
                                Recordatorios importantes
                                {notifications.length > 0 && <NotificationDot />}
                            </MenuItem>
                            <MenuItem onClick={startTutorial} hideOnMobile>
                                Tutorial Interactivo
                            </MenuItem>
                        </>
                    )}

                    {showContactForm && (
                        <FormContainer>
                            <Input
                                name="nombreCompleto"
                                placeholder="Nombre completo"
                                value={formData.nombreCompleto}
                                onChange={handleInputEmailChange}
                            />
                            <Input
                                name="email"
                                placeholder="Correo electrónico"
                                value={formData.email}
                                onChange={handleInputEmailChange}
                            />
                            <TextArea
                                name="mensaje"
                                placeholder="Escribe tu mensaje aquí"
                                value={formData.mensaje}
                                onChange={handleInputEmailChange}
                            />
                            <Button onClick={handleSubmitEmail}>Enviar</Button>
                        </FormContainer>
                    )}

                    {showNotifications && (
                        <CornerNotifications
                            notifications={notifications}
                            headerTitle="Recordatorios importantes"
                        />
                    )}
                </PopupWindow>
            )}

            <Tutorial isActive={isTutorialActive} onClose={closeTutorial} />
            <ToastContainer />
        </>
    );
};

export default HeartIconAnimation;
