import styled, { keyframes } from 'styled-components';
import { FaAngleDown } from 'react-icons/fa';

const popIn = keyframes`
  from {
    transform: translateY(20px) scale(0.9);
    opacity: 0;
  }
  to {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
`;

export const PopupWindow = styled.div`
  position: fixed;
  bottom: 30px;
  right: 90px;
  width: auto;
  max-width: 400px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  z-index: 1050;
  padding: 20px 15px;
  animation: ${popIn} 0.3s ease-out forwards;
  transform-origin: bottom right;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media (max-width: 700px) {
    right: 20px;
    bottom: 20px;
    max-width: 90%;
  }
`;

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.5); }
  100% { transform: scale(1); }
`;

export const NotificationDot = styled.span`
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-left: 8px;
  border-radius: 50%;
  background-color: var(--rojo);
  animation: ${pulse} 1.5s infinite;
  vertical-align: middle;
`;

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
`;

export const MessagePopup = styled.div`
  position: fixed;
  bottom: 130px;
  right: 40px;
  width: 280px;
  background: rgba(255, 255, 255, 0.9);
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  padding: 16px;
  z-index: 9999;
  animation: ${fadeIn} 0.4s ease forwards;

  .close-btn {
    position: absolute;
    top: 8px;
    right: 8px;
    background: transparent;
    border: none;
    color: var(--rojo);
    cursor: pointer;
    font-size: 1rem;
  }

  strong {
    display: block;
    margin-bottom: 6px;
    font-size: 0.95rem;
  }

  p {
    margin: 0;
    font-size: 0.9rem;
  }
`;

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const BackButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  background: transparent;
  border: none;
  color: var(--celeste);
  font-size: 0.95rem;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    color: var(--rojo);
  }

  svg {
    font-size: 1.2rem;
  }
`;

export const CloseButton = styled.button`
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  color: var(--rojo);
  font-size: 1.2rem;
  cursor: pointer;

  &:hover {
    color: var(--rojo-oscuro);
  }
`;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
`;

export const Input = styled.input`
  padding: 10px 14px;
  margin-bottom: 12px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 0.95rem;
  &:focus {
    border-color: var(--celeste);
  }
`;

export const TextArea = styled.textarea`
  padding: 10px 14px;
  margin-bottom: 12px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 0.95rem;
  min-height: 70px;
  resize: vertical;
  &:focus {
    border-color: var(--celeste);
  }
`;

export const Button = styled.button`
  background-color: var(--celeste);
  color: #fff;
  border: none;
  border-radius: 6px;
  padding: 10px 14px;
  cursor: pointer;
  font-weight: 600;
  font-size: 0.95rem;
  transition: background-color 0.2s ease;
  &:hover {
    background-color: var(--celeste-rgba);
  }
`;

export const MenuItem = styled.button`
  width: 100%;
  border: none;
  outline: none;
  margin-bottom: 10px;
  padding: 12px 16px;
  border-radius: 6px;
  background-color: #f7f7f7;
  color: #333;
  font-size: 0.95rem;
  font-weight: 500;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.2s ease;
  &:hover {
    background-color: var(--limon);
  }
  ${({ hideOnMobile }) =>
      hideOnMobile
          ? `
        @media (max-width: 768px) {
          display: none;
        }
      `
          : ``}
`;

export const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1040;
  background-color: transparent;
`;

export const IconContainer = styled.div`
  position: fixed;
  bottom: 80px;
  right: ${(props) => (props.visible ? '20px' : '-60px')};
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  z-index: 1000;
  cursor: pointer;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  transition: right 0.3s ease;
  &:hover {
    right: 20px;
  }
  @media (max-width: 768px) {
    bottom: 60px;
    right: -40px;
    &:hover {
      right: 10px;
    }
  }
`;

export const StyledDropdownContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
`;

export const FloatingButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 0.5rem 1rem;
  background-color: var(--celeste);
  color: var(--blanco);
  border-radius: 20px;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s, transform 0.3s;
  z-index: 10000;
  &:hover {
    background-color: var(--celeste-rgba);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
  @media (max-width: 768px) {
    bottom: 150px;
    top: auto;
    right: 12vw;
    transform: translateX(50%);
  }
`;

export const StyledOption = styled.option`
  color: var(--blanco)!important;
  @media (max-width: 768px) {
    font-size: 1rem;
    padding: 0.5rem;
  }
`;
