// CornerNotifications.jsx
import React from 'react';
import {
    NotificationContainer,
    NotificationTitle,
    NotificationList,
    NotificationItem,
    NotificationItemTitle,
    NotificationItemDesc
} from "./CornerNotificationsStyle";

const CornerNotifications = ({
                                 notifications = [],
                                 headerTitle = 'Recordatorios importantes',
                                 onClose
                             }) => {
    if (!notifications || notifications.length === 0) {
        return null;
    }

    return (
        <NotificationContainer>
            <NotificationTitle>{headerTitle}</NotificationTitle>
            <NotificationList>
                {notifications.map((noti) => (
                    <NotificationItem
                        key={noti.id}
                        style={{ cursor: noti.onClick ? 'pointer' : 'default' }}
                        onClick={() => {
                            if (noti.onClick) {
                                noti.onClick();
                            }
                        }}
                    >
                        <NotificationItemTitle>{noti.titulo}</NotificationItemTitle>
                        <NotificationItemDesc>{noti.descripcion}</NotificationItemDesc>
                    </NotificationItem>
                ))}
            </NotificationList>
        </NotificationContainer>
    );
};

export default CornerNotifications;
