import React from 'react';
import { StyledModal } from '../Modal';

const TermsAndConditionsModal = ({ isOpen, onRequestClose, onAccept }) => {
    return (
        <StyledModal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            width="600px"
            maxWidth="90%"
            height="80%"
            flexDirection="column"
        >
            <div style={{ padding: '20px', maxHeight: '70vh', overflowY: 'auto' }}>
                <h2>Términos y Condiciones de Uso de Vitalink</h2>
                <p>
                    <strong>1. Introducción</strong>
                </p>
                <p>
                    Bienvenido a Vitalink. Estos Términos y Condiciones regulan el acceso y uso de nuestra
                    plataforma de gestión médica basada en tecnología avanzada e inteligencia artificial. Al
                    utilizar nuestros servicios, el usuario acepta cumplir con las disposiciones aquí establecidas.
                    Si no está de acuerdo con estos términos, debe abstenerse de utilizar la plataforma.
                </p>
                <p>
                    <strong>2. Definiciones</strong>
                </p>
                <p>
                    <strong>Usuario:</strong> Cualquier persona, profesional o institución de salud que accede y
                    utiliza Vitalink.
                </p>
                <p>
                    <strong>Plataforma:</strong> El software, sitio web y herramientas asociadas proporcionadas
                    por Vitalink.
                </p>
                <p>
                    <strong>Datos Médicos:</strong> Información relacionada con pacientes gestionada a través de
                    la plataforma.
                </p>
                <p>
                    <strong>IA:</strong> Inteligencia Artificial utilizada para optimizar procesos médicos y
                    administrativos.
                </p>
                <p>
                    <strong>3. Condiciones de Uso</strong>
                </p>
                <p>
                    El acceso a Vitalink está limitado a usuarios mayores de edad, profesionales médicos o
                    instituciones de salud autorizadas. El usuario se compromete a:
                </p>
                <ul>
                    <li>Utilizar la plataforma exclusivamente para fines legales y éticos relacionados con la gestión médica.</li>
                    <li>No intentar acceder, modificar o eliminar datos de manera no autorizada.</li>
                    <li>Proveer información veraz y actualizada.</li>
                </ul>
                <p>
                    Queda prohibido el uso de Vitalink para actividades que contravengan las normativas legales o
                    éticas aplicables al sector salud.
                </p>
                <p>
                    <strong>4. Privacidad y Seguridad de Datos</strong>
                </p>
                <p>
                    Vitalink se compromete a proteger los datos personales y médicos de conformidad con la normativa
                    aplicable, incluyendo el Reglamento General de Protección de Datos (GDPR) y otras leyes locales
                    pertinentes. Entre nuestras medidas de seguridad se incluyen:
                </p>
                <ul>
                    <li>Encriptación de datos en tránsito y almacenamiento.</li>
                    <li>Accesos restringidos y autenticación segura para usuarios.</li>
                    <li>Auditorías periódicas para garantizar la protección de la información.</li>
                </ul>
                <p>
                    El usuario es responsable de mantener la confidencialidad de sus credenciales de acceso.
                </p>
                <p>
                    <strong>5. Propiedad Intelectual</strong>
                </p>
                <p>
                    Todos los derechos sobre el diseño, código fuente, contenido y funcionalidades de Vitalink son
                    propiedad exclusiva de la empresa. Se prohíbe:
                </p>
                <ul>
                    <li>Copiar, modificar o distribuir cualquier parte de la plataforma sin autorización previa.</li>
                    <li>Realizar ingeniería inversa u otros intentos de replicar el software.</li>
                </ul>
                <p>
                    <strong>6. Limitación de Responsabilidad</strong>
                </p>
                <p>
                    Vitalink no se responsabiliza por:
                </p>
                <ul>
                    <li>Errores humanos o negligencia en el uso de la plataforma.</li>
                    <li>Decisiones médicas basadas en información procesada por el sistema.</li>
                    <li>Incumplimientos legales o éticos por parte de los usuarios.</li>
                </ul>
                <p>
                    El usuario reconoce que el software es una herramienta de apoyo y no sustituye el criterio
                    profesional médico.
                </p>
                <p>
                    <strong>7. Pagos y Precios</strong>
                </p>
                <p>
                    El uso de Vitalink está sujeto al pago de suscripciones mensuales o anuales. Las tarifas son
                    publicadas en nuestro sitio web y pueden cambiar previa notificación. Las suscripciones
                    incluyen:
                </p>
                <ul>
                    <li>Acceso a ciertas funcionalidades de la plataforma (dependiendo de la suscripción).</li>
                    <li>Soporte técnico durante el período contratado.</li>
                </ul>
                <p>
                    <strong>8. Políticas de Devolución</strong>
                </p>
                <p>
                    Vitalink ofrece reembolsos en casos justificados, que serán evaluados de acuerdo con los siguientes
                    criterios:
                </p>
                <p>
                    <strong>Casos Elegibles:</strong>
                </p>
                <ul>
                    <li>Fallos técnicos graves en la plataforma que no hayan sido resueltos en un plazo razonable.</li>
                    <li>Cobros incorrectos o duplicados atribuibles a Vitalink.</li>
                </ul>
                <p>
                    <strong>Casos No Elegibles:</strong>
                </p>
                <ul>
                    <li>Insatisfacción general con las funcionalidades de la plataforma.</li>
                    <li>Falta de uso del servicio contratado por parte del usuario.</li>
                </ul>
                <p>
                    <strong>Proceso de Solicitud:</strong> Las solicitudes de reembolso deben enviarse por escrito a
                    soporte@vitalink.es dentro de los 15 días posteriores al incidente.
                </p>
                <p>
                    Vitalink responderá dentro de un plazo de 10 días hábiles con la decisión.
                </p>
                <p>
                    <strong>Decisión Final:</strong> La decisión de otorgar o denegar un reembolso queda a discreción
                    de Vitalink tras analizar las evidencias proporcionadas.
                </p>
                <p>
                    <strong>9. Modificaciones al Servicio</strong>
                </p>
                <p>
                    Vitalink se reserva el derecho de realizar mejoras, actualizar funcionalidades o descontinuar
                    partes del servicio. Estas modificaciones serán notificadas con antelación razonable cuando
                    afecten significativamente a los usuarios.
                </p>
                <p>
                    <strong>10. Cancelación de Cuenta</strong>
                </p>
                <p>
                    Los usuarios pueden solicitar la cancelación de su cuenta en cualquier momento. Al hacerlo, se
                    aplicarán las siguientes condiciones:
                </p>
                <ul>
                    <li>Los datos personales y médicos serán eliminados conforme a la normativa aplicable.</li>
                    <li>La cancelación no genera derecho a reembolsos salvo en los casos indicados.</li>
                </ul>
                <p>
                    <strong>11. Legislación Aplicable</strong>
                </p>
                <p>
                    Estos Términos y Condiciones se rigen por las leyes del país en el que Vitalink opera. Cualquier
                    disputa será sometida a los tribunales competentes de dicha jurisdicción.
                </p>
                <p>
                    <strong>12. Contacto</strong>
                </p>
                <p>
                    Para cualquier consulta o reclamación relacionada con estos términos, por favor contacte a nuestro
                    equipo a través de: <br />
                    Correo electrónico: soporte@vitalink.es
                </p>
                <p>
                    Al utilizar Vitalink, el usuario confirma que ha leído, comprendido y aceptado estos Términos y
                    Condiciones.
                </p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '10px 20px' }}>
                <button
                    onClick={onAccept}
                    style={{
                        marginRight: '10px',
                        padding: '10px 20px',
                        backgroundColor: '#4caf50',
                        color: '#fff',
                        border: 'none',
                        borderRadius: '4px',
                        cursor: 'pointer'
                    }}
                >
                    Acepto
                </button>
                <button
                    onClick={onRequestClose}
                    style={{
                        padding: '10px 20px',
                        backgroundColor: '#f44336',
                        color: '#fff',
                        border: 'none',
                        borderRadius: '4px',
                        cursor: 'pointer'
                    }}
                >
                    Cancelar
                </button>
            </div>
        </StyledModal>
    );
};

export default TermsAndConditionsModal;
