import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import PatientList from '../../components/PatientList/PatientList';
import { StyledModal } from '../../components/Modal';
import { FaSearchPlus, FaSearchMinus, FaDownload } from 'react-icons/fa';
import {
    PdfViewer,
    PdfControls,
    DownloadButton,
    ZoomButton,
    PdfContainer,
    LoaderSpinner,
    LoaderContainer,
    FichaClinicaContainer,
    Title
} from './FichasClinicasStyle';
import { useSede } from '../../context/SedeContext';
import { API_BASE_URL } from "../../utils/config";

const FichaClinica = () => {
    const { idSedeActual } = useSede();
    const [patients, setPatients] = useState([]);
    const [filteredPatients, setFilteredPatients] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedPatient, setSelectedPatient] = useState(null);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [pdfUrl, setPdfUrl] = useState('');
    const [zoomLevel, setZoomLevel] = useState(1);
    const pdfRef = useRef(null);
    const [loading, setLoading] = useState(false);

    // Cargar la lista de pacientes una sola vez
    useEffect(() => {
        const fetchPatients = async () => {
            if (!idSedeActual) {
                console.error('idSedeActual no está definido.');
                return;
            }

            try {
                const response = await axios.get(`${API_BASE_URL}/fichasClinicas/fichas/pacientes`, {
                    params: { idSede: idSedeActual }
                });
                if (response.data.success) {
                    setPatients(response.data.patients);
                    setFilteredPatients(response.data.patients);
                } else {
                    Swal.fire({
                        icon: 'info',
                        title: 'Sin Pacientes',
                        text: 'No se encontraron pacientes con fichas médicas.'
                    });
                }
            } catch (error) {
                console.error('Error al obtener la lista de pacientes:', error);

            }
        };

        fetchPatients();
    }, [idSedeActual]);

    // Filtrado local por nombre/apellido/email
    useEffect(() => {
        if (searchTerm) {
            const lowercasedTerm = searchTerm.toLowerCase();
            setFilteredPatients(
                patients.filter((patient) => {
                    const fullName = `${patient.nombre || ''} ${patient.apellido || ''}`.toLowerCase();
                    const email = (patient.email || '').toLowerCase();
                    return (
                        fullName.includes(lowercasedTerm) ||
                        email.includes(lowercasedTerm)
                    );
                })
            );
        } else {
            setFilteredPatients(patients);
        }
    }, [searchTerm, patients]);

    // Al hacer click en un paciente
    const handleSelectPatient = (patient) => {
        // Revisamos si tiene un signedUrl
        if (!patient.signedUrl) {
            Swal.fire({
                icon: 'info',
                title: 'Sin Ficha',
                text: 'Este paciente no tiene ficha médica disponible.'
            });
            return;
        }

        // Tenemos el link en patient.signedUrl
        setPdfUrl(patient.signedUrl);
        setSelectedPatient(patient);
        setIsModalOpen(true);
    };

    const handleZoomIn = () => {
        setZoomLevel((prevZoom) => prevZoom + 0.1);
    };

    const handleZoomOut = () => {
        if (zoomLevel > 0.1) {
            setZoomLevel((prevZoom) => prevZoom - 0.1);
        }
    };

    // Descargar PDF usando /ficha/download/:uuid
    const handleDownloadPdf = async () => {
        if (!selectedPatient || !selectedPatient.uuid) return;

        try {
            setLoading(true);
            const downloadUrl = `${API_BASE_URL}/fichasClinicas/ficha/download/${selectedPatient.uuid}`;
            const response = await axios.get(downloadUrl, { responseType: 'blob' });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            // Formar nombre de archivo con nombre/apellido o lo que prefieras
            link.setAttribute(
                'download',
                `ficha_${selectedPatient.nombre || 'desconocido'}_${selectedPatient.apellido || 'desconocido'}.pdf`
            );
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error al descargar el PDF:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error de descarga',
                text: 'Hubo un problema al intentar descargar el PDF.'
            });
        } finally {
            setLoading(false);
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setPdfUrl('');
        setSelectedPatient(null);
        setZoomLevel(1);
    };

    return (
        <FichaClinicaContainer>
            <Title>Fichas Clínicas</Title>

            {/* Campo de búsqueda */}
            <input
                type="text"
                placeholder="Buscar pacientes..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{
                    width: '100%',
                    padding: '10px',
                    marginBottom: '20px',
                    borderRadius: '5px',
                    border: '1px solid #ccc',
                }}
            />

            {/* Lista de pacientes */}
            <PatientList
                patients={filteredPatients}
                onSelectPatient={handleSelectPatient}
            />

            {/* Modal con el PDF */}
            <StyledModal
                isOpen={isModalOpen}
                onRequestClose={handleCloseModal}
                width="90%"
                maxWidth="1000px"
                height="80%"
            >
                <PdfContainer>
                    {loading && (
                        <LoaderContainer>
                            <LoaderSpinner />
                        </LoaderContainer>
                    )}

                    {!loading && pdfUrl && (
                        <PdfViewer
                            src={pdfUrl}
                            style={{ transform: `scale(${zoomLevel})` }}
                            ref={pdfRef}
                        />
                    )}

                    <PdfControls>
                        <ZoomButton onClick={handleZoomIn}>
                            <FaSearchPlus /> Acercar
                        </ZoomButton>
                        <ZoomButton onClick={handleZoomOut}>
                            <FaSearchMinus /> Alejar
                        </ZoomButton>
                        <DownloadButton onClick={handleDownloadPdf}>
                            <FaDownload /> Descargar PDF
                        </DownloadButton>
                    </PdfControls>
                </PdfContainer>
            </StyledModal>
        </FichaClinicaContainer>
    );
};

export default FichaClinica;
