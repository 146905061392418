import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import {
    Elements,
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
    useStripe,
    useElements
} from '@stripe/react-stripe-js';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaSpinner } from 'react-icons/fa';
import Navbar from '../../components/Navbar/Navbar';
import {
    BillingContainer,
    BillingTitle,
    BillingForm,
    BillingInput,
    BillingButton,
    BillingLabel,
    BillingError,
    BillingSection,
    CreditCardContainer,
    CreditCard3D,
    CardDetails,
    CardNumber,
    CardHolder,
    CardExpiry,
    CardCvc,
    TooltipContainer,
    TooltipContent,
    RequiredAsterisk
} from './BillingStyle';
import { API_BASE_URL_BILLING_SERVICE, API_BASE_URL } from '../../utils/config';
import { AuthContext } from '../../context/AuthContext';
import TermsAndConditionsModal from '../../components/TermsAndConditionsModal/TermsAndConditionsModal';

// Inicializar Stripe con la clave pública
const stripePromise = loadStripe('pk_test_51Pp2jVP8m7371eZyB3dTDowrN8UCjKdrpla1DaqbOtubHf7Dogb6ukjTKBfUDAaBy81ViLhErdI3KZH7C1MROmgV00epg4sLn6');

const BillingFormComponent = () => {
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();
    const location = useLocation();
    const { userData } = useContext(AuthContext);

    // Estado para saber si se trata de una compra subsecuente (renovación)
    const [isRenewal, setIsRenewal] = useState(false);

    // Obtener el plan desde location.state o de localStorage (para evitar que el precio aparezca como undefined)
    const storedPlan = localStorage.getItem('selectedPlan');
    const selectedPlan = location.state?.plan || (storedPlan ? JSON.parse(storedPlan) : {});

    // Si se recibe el plan por location.state, lo guardamos en localStorage
    useEffect(() => {
        if (location.state?.plan) {
            localStorage.setItem('selectedPlan', JSON.stringify(location.state.plan));
        }
    }, [location.state?.plan]);

    // Estados del formulario
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [birthdate, setBirthdate] = useState('');
    const [phone, setPhone] = useState('+502');

    // Para nuevos clientes usaremos un único input que se asigna a ambos campos
    const [institutionName, setInstitutionName] = useState('');
    const [sedeName, setSedeName] = useState('');
    const [zip, setZip] = useState('');

    // Estados para errores, validaciones y efecto 3D de la tarjeta
    const [error, setError] = useState(null);
    const [rotation, setRotation] = useState({ x: 0, y: 0 });
    const [institutions, setInstitutions] = useState([]);
    const [sedes, setSedes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [validationMessages, setValidationMessages] = useState([]);

    // Estados para el Modal de Términos y Condiciones y aceptación de los mismos
    const [showTermsModal, setShowTermsModal] = useState(false);
    const [acceptedTerms, setAcceptedTerms] = useState(false);

    // Cargar las instituciones (para usuarios logueados)
    useEffect(() => {
        const fetchInstitutions = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/general/instituciones`);
                setInstitutions(response.data);
            } catch (error) {
                console.error('Error fetching institutions:', error);
                toast.error('Error al obtener las instituciones.');
            }
        };
        fetchInstitutions();
    }, []);

    // Para usuarios logueados: al cambiar la institución (aunque estará bloqueada) se pueden cargar sus sedes
    const handleInstitutionChange = async (e) => {
        const value = e.target.value.trim();
        setInstitutionName(value);
        const selectedInstitution = institutions.find(
            (inst) => inst.NOMBRE.toLowerCase() === value.toLowerCase()
        );
        if (selectedInstitution) {
            try {
                const response = await axios.get(
                    `${API_BASE_URL}/general/instituciones/${selectedInstitution.ID_INSTITUCION}/sedes`
                );
                setSedes(response.data);
            } catch (error) {
                console.error('Error fetching sedes:', error);
                toast.error('Error al obtener las sedes de la institución.');
                setSedes([]);
            }
        } else {
            setSedes([]);
        }
    };

    // Maneja el cambio en el input de sede
    const handleSedeChange = (e) => {
        const value = e.target.value.trim();
        setSedeName(value);
    };

    // Manejadores de Stripe Elements
    const handleInputChange = (event) => {
        setError(event.error ? event.error.message : null);
    };

    // Efecto 3D de la tarjeta
    const handleMouseMove = (e) => {
        const { offsetX, offsetY } = e.nativeEvent;
        const x = (offsetY / e.target.offsetHeight) * 30 - 15;
        const y = (offsetX / e.target.offsetWidth) * 30 - 15;
        setRotation({ x, y });
    };

    const handleMouseLeave = () => {
        setRotation({ x: 0, y: 0 });
    };

    // Si no está logueado, redirigir a login (con state para retornar a billing)
    const handleLoginRedirect = () => {
        navigate('/login', { state: { redirectTo: '/billing', plan: selectedPlan } });
    };

    // Si ya está logueado, cargar la institución del userData y habilitar renovación
    const handleRenewClick = () => {
        if (userData && userData.instituciones && userData.instituciones.length > 0) {
            setInstitutionName(userData.instituciones[0].INSTITUCION);
            setIsRenewal(true);
            toast.info('Se ha cargado tu institución. Ahora ingresa el nombre de la nueva sede.');
        } else {
            toast.error('No se encontró la información de tu institución. Por favor, inicia sesión.');
        }
    };

    // Validación del formulario
    const validateForm = () => {
        const messages = [];

        // Validaciones generales
        if (!email.trim()) messages.push('Correo Electrónico');
        if (!name.trim()) messages.push('Nombre');
        if (!lastName.trim()) messages.push('Apellido');
        if (!birthdate.trim()) messages.push('Fecha de Nacimiento');
        if (!phone || phone.length < 5) messages.push('Teléfono');
        if (!zip.trim()) messages.push('Código Postal');

        // Validación de formato de email
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (email && !emailRegex.test(email)) {
            messages.push('Correo Electrónico inválido');
        }

        // Validación de código postal (ejemplo con 5 dígitos)
        const zipRegex = /^\d{5}$/;
        if (zip && !zipRegex.test(zip)) {
            messages.push('Código Postal inválido');
        }

        // VALIDACIÓN MODIFICADA:
        // Si el usuario está logueado (userData existe), no validamos la institución ya que viene bloqueada;
        // de lo contrario, validamos el campo de institución.
        if (userData) {
            if (!sedeName.trim()) messages.push('Sede');
        } else {
            if (!institutionName.trim()) messages.push('Clínica o Institución');
        }

        setValidationMessages(messages);
        return messages.length === 0;
    };

    // Chequea validaciones en tiempo real
    useEffect(() => {
        validateForm();
        // eslint-disable-next-line
    }, [email, name, lastName, birthdate, phone, institutionName, zip, sedeName, sedes, institutions, isRenewal, userData]);

    // Maneja el submit del formulario
    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!acceptedTerms) {
            toast.error('Debe aceptar los Términos y Condiciones para continuar.');
            return;
        }

        if (!stripe || !elements) {
            setError('Stripe.js no ha cargado todavía. Por favor, inténtalo de nuevo más tarde.');
            toast.error('Stripe.js no ha cargado todavía. Inténtalo más tarde.');
            return;
        }

        if (!validateForm()) {
            toast.error('Por favor, completa todos los campos requeridos correctamente.');
            return;
        }

        setLoading(true);
        const cardNumberElement = elements.getElement(CardNumberElement);
        if (!cardNumberElement) {
            setError('Los detalles de la tarjeta no se han cargado correctamente.');
            toast.error('Los detalles de la tarjeta no se han cargado correctamente.');
            setLoading(false);
            return;
        }

        try {
            // Crea el método de pago con Stripe
            const { error: stripeError, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: cardNumberElement,
                billing_details: {
                    email: email,
                    name: `${name} ${lastName}`,
                    address: { postal_code: zip },
                    phone: phone
                }
            });

            if (stripeError) {
                setError(stripeError.message);
                toast.error(stripeError.message);
                setLoading(false);
                return;
            }

            // Si el usuario ya tiene institución, tomamos el ID de la primera que aparezca en userData
            const institutionId = userData?.instituciones?.[0]?.ID_INSTITUCION ?? null;

            // Construimos la data a enviar a nuestro backend
            const paymentData = {
                amount: selectedPlan.precio ? selectedPlan.precio * 100 : 0,
                currency: 'usd',
                description: selectedPlan.titulo || '',
                name,
                lastName,
                birthdate,
                email,
                phone,
                planId: selectedPlan.id,
                institutionId,
                institutionName,
                sedeName,
                zip,
                paymentMethodId: paymentMethod.id,
                editedBy: null
            };

            // Llamamos a nuestro backend de pago
            const paymentResponse = await axios.post(
                `${API_BASE_URL_BILLING_SERVICE}/payment`,
                paymentData,
                {
                    headers: { 'Content-Type': 'application/json' }
                }
            );

            if (paymentResponse.status !== 200 && paymentResponse.status !== 201) {
                const errorMsg = paymentResponse.data.error || 'El pago falló';
                setError(errorMsg);
                toast.error(errorMsg);
                setLoading(false);
            } else {
                toast.success('Pago realizado con éxito. Se ha enviado un correo.');
                setTimeout(() => {
                    setLoading(false);
                    navigate('/login');
                }, 1000);
            }
        } catch (error) {
            console.error('Error procesando el pago:', error);
            setError('Ocurrió un error inesperado al procesar tu pago.');
            toast.error('Ocurrió un error inesperado al procesar tu pago.');
            setLoading(false);
        }
    };

    return (
        <>
            <Navbar />
            <BillingContainer>
                <BillingTitle>Procesar Pago</BillingTitle>
                <CreditCardContainer onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave}>
                    <CreditCard3D style={{ transform: `rotateY(${rotation.y}deg) rotateX(${rotation.x}deg)` }}>
                        <CardDetails>
                            <CardNumber>1234 5678 9012 3456</CardNumber>
                            <CardHolder>JOHN DOE</CardHolder>
                            <CardExpiry>12/25</CardExpiry>
                            <CardCvc>123</CardCvc>
                        </CardDetails>
                    </CreditCard3D>
                </CreditCardContainer>

                <BillingForm onSubmit={handleSubmit}>
                    {/* Sección 1: Datos personales */}
                    <BillingSection>
                        <BillingLabel>
                            Correo Electrónico<RequiredAsterisk>*</RequiredAsterisk>
                        </BillingLabel>
                        <BillingInput
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            aria-required="true"
                        />

                        <BillingLabel>
                            Nombre/s<RequiredAsterisk>*</RequiredAsterisk>
                        </BillingLabel>
                        <BillingInput
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                            aria-required="true"
                        />

                        <BillingLabel>
                            Apellido/s<RequiredAsterisk>*</RequiredAsterisk>
                        </BillingLabel>
                        <BillingInput
                            type="text"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            required
                            aria-required="true"
                        />

                        <BillingLabel>
                            Fecha de Nacimiento<RequiredAsterisk>*</RequiredAsterisk>
                        </BillingLabel>
                        <BillingInput
                            type="date"
                            value={birthdate}
                            onChange={(e) => setBirthdate(e.target.value)}
                            required
                            aria-required="true"
                        />

                        <BillingLabel>
                            Teléfono<RequiredAsterisk>*</RequiredAsterisk>
                        </BillingLabel>
                        <PhoneInput
                            international
                            defaultCountry="GT"
                            value={phone}
                            onChange={setPhone}
                            required
                            aria-required="true"
                        />
                    </BillingSection>

                    {/* Sección 2: Datos de la institución/sede y código postal */}
                    <BillingSection>
                        {userData ? (
                            <>
                                <BillingLabel>
                                    Institución<RequiredAsterisk>*</RequiredAsterisk>
                                </BillingLabel>
                                <BillingInput
                                    type="text"
                                    value={
                                        institutionName ||
                                        (userData.instituciones &&
                                            userData.instituciones[0]?.INSTITUCION) ||
                                        ''
                                    }
                                    onChange={handleInstitutionChange}
                                    placeholder="Nombre de la institución"
                                    required
                                    aria-required="true"
                                    disabled
                                />

                                <BillingLabel>
                                    Sede (Nueva)<RequiredAsterisk>*</RequiredAsterisk>
                                </BillingLabel>
                                <BillingInput
                                    type="text"
                                    value={sedeName}
                                    onChange={handleSedeChange}
                                    placeholder="Escribe el nombre de la nueva sede"
                                    required
                                    aria-required="true"
                                />
                            </>
                        ) : (
                            <>
                                <BillingLabel>
                                    Clínica o Institución<RequiredAsterisk>*</RequiredAsterisk>
                                </BillingLabel>
                                <BillingInput
                                    type="text"
                                    value={institutionName}
                                    onChange={(e) => {
                                        setInstitutionName(e.target.value);
                                        setSedeName(e.target.value);
                                    }}
                                    placeholder="Escribe el nombre de la clínica o institución"
                                    required
                                    aria-required="true"
                                />
                                <p
                                    style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                                    onClick={handleLoginRedirect}
                                >
                                    ¿Ya eres cliente? Inicia sesión para agregar una nueva institución.
                                </p>
                            </>
                        )}

                        <BillingLabel>
                            Código Postal<RequiredAsterisk>*</RequiredAsterisk>
                        </BillingLabel>
                        <BillingInput
                            type="text"
                            value={zip}
                            onChange={(e) => setZip(e.target.value)}
                            required
                            aria-required="true"
                        />
                    </BillingSection>

                    {/* Sección 3: Datos de la tarjeta */}
                    <BillingSection>
                        <BillingLabel>
                            Número de Tarjeta<RequiredAsterisk>*</RequiredAsterisk>
                        </BillingLabel>
                        <CardNumberElement
                            options={{
                                style: {
                                    base: {
                                        fontSize: '16px',
                                        color: '#424770',
                                        '::placeholder': { color: '#aab7c4' }
                                    },
                                    invalid: { color: '#9e2146' }
                                }
                            }}
                            onChange={handleInputChange}
                        />
                    </BillingSection>

                    <BillingSection>
                        <BillingLabel>
                            Fecha de Expiración<RequiredAsterisk>*</RequiredAsterisk>
                        </BillingLabel>
                        <CardExpiryElement
                            options={{
                                style: {
                                    base: {
                                        fontSize: '16px',
                                        color: '#424770',
                                        '::placeholder': { color: '#aab7c4' }
                                    },
                                    invalid: { color: '#9e2146' }
                                }
                            }}
                            onChange={handleInputChange}
                        />
                    </BillingSection>

                    <BillingSection>
                        <BillingLabel>
                            CVC<RequiredAsterisk>*</RequiredAsterisk>
                        </BillingLabel>
                        <CardCvcElement
                            options={{
                                style: {
                                    base: {
                                        fontSize: '16px',
                                        color: '#424770',
                                        '::placeholder': { color: '#aab7c4' }
                                    },
                                    invalid: { color: '#9e2146' }
                                }
                            }}
                            onChange={handleInputChange}
                        />
                    </BillingSection>

                    {/* Sección 4: Aceptación de Términos y Condiciones */}
                    <BillingSection style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                        <input
                            type="checkbox"
                            id="termsAccepted"
                            checked={acceptedTerms}
                            onChange={(e) => setAcceptedTerms(e.target.checked)}
                            style={{ marginRight: '8px', verticalAlign: 'middle' }}
                        />
                        <label
                            htmlFor="termsAccepted"
                            style={{ margin: 0, display: 'flex', alignItems: 'center', gap: '4px' }}
                        >
                            Acepto los{' '}
                            <span
                                style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                                onClick={() => setShowTermsModal(true)}
                            >
                Términos y Condiciones
              </span>
                            <RequiredAsterisk>*</RequiredAsterisk>
                        </label>
                    </BillingSection>

                    {error && <BillingError>{error}</BillingError>}

                    <TooltipContainer>
                        <BillingButton
                            type="submit"
                            disabled={!stripe || loading || validationMessages.length > 0 || !acceptedTerms}
                        >
                            {loading ? (
                                <FaSpinner className="spinner" />
                            ) : (
                                `Pagar $${selectedPlan.precio || 0}`
                            )}
                        </BillingButton>

                        {validationMessages.length > 0 && (
                            <TooltipContent>
                                <strong>Revisa lo siguiente:</strong>
                                <ul>
                                    {validationMessages.map((message, index) => (
                                        <li key={index}>{message}</li>
                                    ))}
                                </ul>
                            </TooltipContent>
                        )}
                    </TooltipContainer>
                </BillingForm>
            </BillingContainer>
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar />
            <TermsAndConditionsModal
                isOpen={showTermsModal}
                onRequestClose={() => setShowTermsModal(false)}
                onAccept={() => {
                    setAcceptedTerms(true);
                    setShowTermsModal(false);
                }}
            />
        </>
    );
};

const Billing = () => {
    return (
        <Elements stripe={stripePromise}>
            <BillingFormComponent />
        </Elements>
    );
};

export default Billing;
